import React, { useState } from 'react';

import TypographyDashboardFonts from './TypographyDashboardFonts';
import TypographyDashboardLayouts from './TypographyDashboardLayouts';
import TypographyDashboardSizes from './TypographyDashboardSizes';
import TypographyDashboardAlign from './TypographyDashboardAlign';
import TypographyDashboardSpacing from './TypographyDashboardSpacing';
import TypographyDashboardDownload from './TypographyDashboardDownload';
import TypographyDashboardCSS from './TypographyDashboardCSS';

export default function TypographyDashboard(props) {
  // console.log('rendered TypographyDashboard Component');
  // // toggle menu exclusive
  // //
  // choose fonts menu
  const [showChooseFontsMenu, setShowChooseFontsMenu] = useState(false);
  function toggleChooseFontsMenu() {
    setShowChooseFontsMenu(!showChooseFontsMenu);
    setShowLayoutsMenu(false);
    setShowSizesMenu(false);
    setShowAlignMenu(false);
    setShowSpacingMenu(false);
    setShowCSSMenu(false);
    setShowDownloadMenu(false);
  }
  // choose Layouts menu
  const [showLayoutsMenu, setShowLayoutsMenu] = useState(false);
  function toggleLayoutsMenu() {
    setShowLayoutsMenu(!showLayoutsMenu);
    setShowChooseFontsMenu(false);
    setShowSizesMenu(false);
    setShowAlignMenu(false);
    setShowSpacingMenu(false);
    setShowCSSMenu(false);
    setShowDownloadMenu(false);
  }
  // choose Sizes menu
  const [showSizesMenu, setShowSizesMenu] = useState(false);
  function toggleSizesMenu() {
    setShowSizesMenu(!showSizesMenu);
    setShowChooseFontsMenu(false);
    setShowLayoutsMenu(false);
    setShowAlignMenu(false);
    setShowSpacingMenu(false);
    setShowCSSMenu(false);
    setShowDownloadMenu(false);
  }
  // adjust alignments menu
  const [showAlignMenu, setShowAlignMenu] = useState(false);
  function toggleAlignMenu() {
    setShowAlignMenu(!showAlignMenu);
    setShowChooseFontsMenu(false);
    setShowLayoutsMenu(false);
    setShowSizesMenu(false);
    setShowSpacingMenu(false);
    setShowCSSMenu(false);
    setShowDownloadMenu(false);
  }
  // adjust spacing menu
  const [showSpacingMenu, setShowSpacingMenu] = useState(false);
  function toggleSpacingMenu() {
    setShowSpacingMenu(!showSpacingMenu);
    setShowChooseFontsMenu(false);
    setShowLayoutsMenu(false);
    setShowSizesMenu(false);
    setShowAlignMenu(false);
    setShowCSSMenu(false);
    setShowDownloadMenu(false);
  }
  // export CSS menu
  const [showCSSMenu, setShowCSSMenu] = useState(false);
  function toggleCSSMenu() {
    setShowCSSMenu(!showCSSMenu);
    setShowChooseFontsMenu(false);
    setShowLayoutsMenu(false);
    setShowSizesMenu(false);
    setShowAlignMenu(false);
    setShowSpacingMenu(false);
    setShowDownloadMenu(false);
  }
  // choose fonts menu
  const [showDownloadMenu, setShowDownloadMenu] = useState(false);
  function toggleDownloadMenu() {
    setShowDownloadMenu(!showDownloadMenu);
    setShowChooseFontsMenu(false);
    setShowLayoutsMenu(false);
    setShowSizesMenu(false);
    setShowAlignMenu(false);
    setShowSpacingMenu(false);
    setShowCSSMenu(false);
  }

  return (
    <div>
      <div
        className={props.darkmode ? 'dashboardSubMenu' : 'dashboardSubMenuDM'}
      >
        <TypographyDashboardFonts
          darkmode={props.darkmode}
          googleFontsData={props.googleFontsData}
          showChooseFontsMenu={showChooseFontsMenu}
          toggleChooseFontsMenu={toggleChooseFontsMenu}
          headingsFontFamily={props.headingsFontFamily}
          headingsFontWeight={props.headingsFontWeight}
          bodyFontFamily={props.bodyFontFamily}
          bodyFontWeight={props.bodyFontWeight}
          setHeadingsFontCallback={props.setHeadingsFontCallback}
          setBodyFontCallback={props.setBodyFontCallback}
          changeHeadingsFontOnHover={props.changeHeadingsFontOnHover}
          changeHeadingsFontOnMouseLeave={props.changeHeadingsFontOnMouseLeave}
          changeBodyFontOnHover={props.changeBodyFontOnHover}
          changeBodyFontOnMouseLeave={props.changeBodyFontOnMouseLeave}
          setTypographyMenuState={props.setTypographyMenuState}
        />
      </div>
      <div
        className={props.darkmode ? 'dashboardSubMenu' : 'dashboardSubMenuDM'}
      >
        <TypographyDashboardLayouts
          darkmode={props.darkmode}
          showLayoutsMenu={showLayoutsMenu}
          toggleLayoutsMenu={toggleLayoutsMenu}
          changeFontLayoutBasic={props.changeFontLayoutBasic}
          changeFontLayoutBlock={props.changeFontLayoutBlock}
          changeFontLayoutComplete={props.changeFontLayoutComplete}
        />
      </div>
      <div
        className={props.darkmode ? 'dashboardSubMenu' : 'dashboardSubMenuDM'}
      >
        <TypographyDashboardSizes
          darkmode={props.darkmode}
          showSizesMenu={showSizesMenu}
          toggleSizesMenu={toggleSizesMenu}
          sizeRangeH1={props.sizeRangeH1}
          setFontSizeValues={props.setFontSizeValues}
          typographyLayoutSelection={props.typographyLayoutSelection}
        />
      </div>
      <div
        className={props.darkmode ? 'dashboardSubMenu' : 'dashboardSubMenuDM'}
      >
        <TypographyDashboardAlign
          darkmode={props.darkmode}
          showAlignMenu={showAlignMenu}
          toggleAlignMenu={toggleAlignMenu}
          setTypographyAlignmentCallback={props.setTypographyAlignmentCallback}
        />
      </div>
      <div
        className={props.darkmode ? 'dashboardSubMenu' : 'dashboardSubMenuDM'}
      >
        <TypographyDashboardSpacing
          darkmode={props.darkmode}
          showSpacingMenu={showSpacingMenu}
          toggleSpacingMenu={toggleSpacingMenu}
          setLineHeightValues={props.setLineHeightValues}
          setBodyMarginBottomValues={props.setBodyMarginBottomValues}
        />
      </div>
      <div
        className={props.darkmode ? 'dashboardSubMenu' : 'dashboardSubMenuDM'}
      >
        <TypographyDashboardCSS
          darkmode={props.darkmode}
          cssDownloadTrigger={props.cssDownloadTrigger}
          showCSSMenu={showCSSMenu}
          toggleCSSMenu={toggleCSSMenu}
        />
      </div>
      <div
        className={props.darkmode ? 'dashboardSubMenu' : 'dashboardSubMenuDM'}
      >
        <TypographyDashboardDownload
          darkmode={props.darkmode}
          googleFontsData={props.googleFontsData}
          showDownloadMenu={showDownloadMenu}
          toggleDownloadMenu={toggleDownloadMenu}
          headingsFontFamily={props.headingsFontFamily}
          headingsFontWeight={props.headingsFontWeight}
          bodyFontFamily={props.bodyFontFamily}
          bodyFontWeight={props.bodyFontWeight}
          headingsFontDownloadTrigger={props.headingsFontDownloadTrigger}
          bodyFontDownloadTrigger={props.bodyFontDownloadTrigger}
        />
      </div>
    </div>
  );
}
