import React from 'react';

export default function TpographyFontCategoryButton(props) {
  function onClickFunctions(fontCategory, fontName) {
    props.setFilterStateCategoryListFunction(fontCategory);
    props.setActiveButtonFunction(fontName);
  }

  return (
    <div className={'fontCategoryButtonCont'}>
      <button
        className={
          props.darkmode
            ? props.activeButtonState === props.name
              ? 'fontCategoryButtonActive'
              : 'fontCategoryButton'
            : props.activeButtonState === props.name
            ? 'fontCategoryButtonActiveDM'
            : 'fontCategoryButtonDM'
        }
        onClick={() => onClickFunctions(props.buttonFontCategory, props.name)}
      >
        {props.name}
      </button>
    </div>
  );
}
