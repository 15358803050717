import React, { useEffect } from 'react';

import TypographyDisplayItem from './TypographyDisplayItem';

export default function TypographyDisplay(props) {
  // console.log('rendered TypographyDisplay Component');

  useEffect(() => {
    props.setFontStylesOnLayoutChange();
  });

  return (
    <div className="TypographyContent">
      {props.typographyDisplayDataState.map(
        ({ id, htmlTag, content }, index) => {
          return (
            <TypographyDisplayItem
              key={id}
              id={id}
              htmlTag={htmlTag}
              content={content}
              index={index}
              darkmode={props.darkmode}
            />
          );
        }
      )}
    </div>
  );
}
