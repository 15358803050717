import React from 'react';

export default function LoadingScreen(props) {
  let fontsTotal = props.googleFontsData.length;
  let fontsTotalMessage =
    'Loading a total Amount of ' + fontsTotal + ' Fonts ...';
  // console.log('fontsTotal in loadingScreen: ' + fontsTotal);
  function loadingScreenMessaging() {
    if (props.showLoadingScreen) {
      setTimeout(() => {
        startMessaging();
      }, 1000);
      function startMessaging() {
        setTimeout(() => {
          document.getElementById('loadingMessage').innerHTML =
            'Fetching Google Fonts API Data ...';
        }, 1500);
        setTimeout(() => {
          document.getElementById('loadingMessage').innerHTML =
            'Data fetched successfully.';
        }, 3000);
        setTimeout(() => {
          document.getElementById(
            'loadingMessage'
          ).innerHTML = fontsTotalMessage;
        }, 4000);
        setTimeout(() => {
          document.getElementById('loadingMessage').innerHTML = 'Fonts loaded.';
        }, 5600);
        setTimeout(() => {
          props.switchOffLoadingScreen();
        }, 6700);
      }
    }
  }
  loadingScreenMessaging();

  return (
    <div className="lSPageCont">
      <div className="lSPageContent">
        <div className="circle">
          <span className="circle__btn">
            <h3 id={'loadingMessage'}>Connecting to Google Server ...</h3>
          </span>
          <span className="circle__back-1"></span>
          <span className="circle__back-2"></span>
        </div>{' '}
      </div>
    </div>
  );
}
