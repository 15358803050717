import React from 'react';

export default function TypographyFontItem(props) {
  let itemFontFamily = "'" + props.item.fontFamily + "'";

  if (props.chooseFontsMode === 'headingsFont') {
    return (
      <div key={props.item.displayName}>
        <div
          className={
            props.darkmode ? 'typographyfontItem' : 'typographyFontItemDM'
          }
          style={{
            fontFamily: itemFontFamily,
            fontWeight: props.item.fontWeight,
            fontStyle: props.item.fontStyle,
            height: '1.6rem',
            width: '100%',
            lineHeight: '1rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() =>
            props.onClickEvents(
              props.item.fontFamily,
              props.item.fontWeight,
              props.item.fontStyle
            )
          }
          onMouseOver={props.changeHeadingsFontOnHover}
          onMouseLeave={props.changeHeadingsFontOnMouseLeave}
        >
          {props.item.displayName}
        </div>
      </div>
    );
  } else if (props.chooseFontsMode === 'bodyFont') {
    return (
      <div key={props.item.displayName}>
        <div
          className={
            props.darkmode ? 'typographyfontItem' : 'typographyFontItemDM'
          }
          style={{
            fontFamily: itemFontFamily,
            fontWeight: props.item.fontWeight,
            fontStyle: props.item.fontStyle,
            height: '1.6rem',
            width: '100%',
            lineHeight: '1rem',
            whiteSpace: 'nowrap',
          }}
          onClick={() =>
            props.onClickEvents(
              props.item.fontFamily,
              props.item.fontWeight,
              props.item.fontStyle
            )
          }
          onMouseOver={props.changeBodyFontOnHover}
          onMouseLeave={props.changeBodyFontOnMouseLeave}
        >
          {props.item.displayName}
        </div>
      </div>
    );
  }
}
