import React from 'react';
import classNames from 'classnames';

export default function TypographyDisplayItem(props) {
  var headingsClasses = classNames(
    props.darkmode ? 'typographyDisplayItem' : 'typographyDisplayItemDM',
    { typographyDisplayItemHeading: true }
  );
  var bodyClasses = classNames(
    props.darkmode ? 'typographyDisplayItem' : 'typographyDisplayItemDM',
    { typographyDisplayItemBody: true },
    { bodyP: true }
  );

  switch (props.htmlTag) {
    case 'p':
      return (
        <p id="bodyP" className={bodyClasses}>
          {props.content}
        </p>
      );
    case 'h1':
      return (
        <h1 id="headingsH1" className={headingsClasses}>
          {props.content}
        </h1>
      );
    case 'h2':
      return (
        <h2 id="headingsH2" className={headingsClasses}>
          {props.content}
        </h2>
      );
    case 'h3':
      return (
        <h3 id="headingsH3" className={headingsClasses}>
          {props.content}
        </h3>
      );
    case 'h4':
      return (
        <h4 id="headingsH4" className={headingsClasses}>
          {props.content}
        </h4>
      );
    case 'h5':
      return (
        <h5 id="headingsH5" className={headingsClasses}>
          {props.content}
        </h5>
      );
    case 'h6':
      return (
        <h6 id="headingsH6" className={headingsClasses}>
          {props.content}
        </h6>
      );
    default:
      return null;
  }
}
