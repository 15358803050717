import React from 'react';

import Header from '../components/Header';

export default function Support(props) {
  return (
    <div
      className={props.darkmode ? 'pageContainer-sub' : 'pageContainer-subDM'}
    >
      <div
        className={props.darkmode ? 'sideBarContainer' : 'sideBarContainerDM'}
      >
        <Header
          toggleDarkmode={props.toggleDarkmode}
          darkmode={props.darkmode}
        />
        <h1 className={props.darkmode ? 'subPageHeading' : 'subPageHeadingDM'}>
          Support
        </h1>
      </div>
      <div
        className={
          props.darkmode
            ? 'subPageContentContainer'
            : 'subPageContentContainerDM'
        }
      >
        <div></div>
        <h2 className={props.darkmode ? 'subPageHeading' : 'subPageHeadingDM'}>
          Hey there
        </h2>
        <p>
          If you found this tool to be useful and want to support further
          development, feel free to{' '}
          <a
            href="https://paypal.me/notmytype?locale.x=de_DE"
            target="_blank"
            rel="noreferrer"
          >
            buy me a coffee
          </a>
          .
        </p>{' '}
        <p>
          Other than that, your experience, feedback, or even ideas for future
          features are much appreciated and can be send to me{' '}
          <a href="mailto:info@design-berlin.net?subject=NotMyType Support">
            via mail
          </a>
          .
        </p>
        <p>
          If not, check in some other time, there will be some upgrades, for
          sure! Happy designing!
        </p>
      </div>
      <div
        className={props.darkmode ? 'googleAdsenseCont' : 'googleAdsenseContDM'}
      ></div>
    </div>
  );
}
