import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Layout from './components/Layout';

export default function App() {
  // console.log('rendered App component');
  const [error, setError] = useState(false);
  const [googleFontsData, setGoogleFontsData] = useState([]);

  // API fetch
  async function fetchData() {
    // console.log('Fetching Google Fonts API Data ...');
    const res = await fetch(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDwAAi4YYi-DgiEWpjoPfvUQm2ULikxfqc'
    );
    res
      .json()
      .then(
        (res) => setGoogleFontsData(res.items)
        // console.log('Data fetched successfully.')
      )
      .catch((error) => setError(error));
  }

  var fontsTotal;
  // html head link for google fonts
  function fontLoader() {
    // font variables
    // italic weights
    const italicWeights = [
      '100italic',
      '200italic',
      '300italic',
      '400italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ];
    // regular weights
    const regularWeights = [
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
    ];

    if (googleFontsData.length) {
      // fonts total
      fontsTotal = googleFontsData.length;
      // console.log('fontsTotal: ' + fontsTotal);
      // number of links needed
      var fontsLinkAmount = Math.ceil(fontsTotal / 100);
      // console.log('fontsLinkAmount: ' + fontsLinkAmount);
      // WIP limiting fontlinks to 1
      // fontsLinkAmount = 1;

      // initialize link counter
      let fontLinksCount = 0;
      // console.log('fontLinksCount: ' + fontLinksCount);
      // fetching document body tag
      const bodyID = document.getElementsByTagName('body')[0];

      // creating link instances for each 100 fonts
      let arrayOfLinks = [];

      //  creating links loop
      for (let i = 0; i < fontsLinkAmount; i++) {
        // creating link
        arrayOfLinks[i] = document.createElement('link');
        arrayOfLinks[i].type = 'text/css';
        arrayOfLinks[i].rel = 'stylesheet';
        arrayOfLinks[i].href = 'https://fonts.googleapis.com/css?family=';

        // declaring createLinkFunction
        function createLinkRequest(item, arrayOfLinks) {
          // console.log('arrayOfLinks' + arrayOfLinks);
          // adding font family
          arrayOfLinks.href += item.family;
          // console.log('arrayOfLinks.href: ' + arrayOfLinks.href);
          // adding font weight prefix
          arrayOfLinks.href += ':ital,wght@';
          // looping over regular font weights
          for (let i = 0; i < regularWeights.length; i++) {
            if (item.variants.includes(regularWeights[i])) {
              arrayOfLinks.href += '0,' + regularWeights[i] + ';';
            }
          }
          // looping over italic font weights
          for (let i = 0; i < italicWeights.length; i++) {
            if (item.variants.includes(italicWeights[i])) {
              arrayOfLinks.href += '1,' + regularWeights[i] + ';';
            }
          }
          // fonts separator
          arrayOfLinks.href += '|';
        }

        googleFontsData.forEach((item, index) => {
          // log index
          // console.log('googleFontsData.map index: ' + index);
          // creating links with 100 fonts each
          if (index < 100 && fontLinksCount === 0) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 100 && index < 200 && fontLinksCount === 1) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 200 && index < 300 && fontLinksCount === 2) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 300 && index < 400 && fontLinksCount === 3) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 400 && index < 500 && fontLinksCount === 4) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 500 && index < 600 && fontLinksCount === 5) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 600 && index < 700 && fontLinksCount === 6) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 700 && index < 800 && fontLinksCount === 7) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 800 && index < 900 && fontLinksCount === 8) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 900 && index < 1000 && fontLinksCount === 9) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 1000 && index < 1100 && fontLinksCount === 10) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 1100 && index < 1200 && fontLinksCount === 11) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 1200 && index < 1300 && fontLinksCount === 12) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 1300 && index < 1400 && fontLinksCount === 13) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 1400 && index < 1500 && fontLinksCount === 14) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else if (index >= 1500 && index < 1600 && fontLinksCount === 15) {
            createLinkRequest(item, arrayOfLinks[i]);
          } else {
            return;
          }
        });

        // setting loading font;
        arrayOfLinks[i].href += '&display=swap';
        // last href modifications
        let str;
        let newStr;
        str = arrayOfLinks[i].href;
        // removing encoded spaces
        newStr = str.replace(/%20/g, '+');
        arrayOfLinks[i].href = newStr;
        // counting font links
        fontLinksCount++;
        // setGoogleFontsHttpRequests(true);
        // console.log('Fonts loaded.');
      }
      // calling attach links function
      attachLinks(fontsLinkAmount, arrayOfLinks, bodyID);
      // console.log('triggered attachLinks()');
    }
  }

  // attaching links to body with timeout
  function attachLinks(fontsLinkAmount, arrayOfLinks, bodyID) {
    for (let i = 0; i < fontsLinkAmount; i++) {
      setTimeout(function () {
        // console.log('attaching link ' + i);
        // attaching links to body
        // console.log('arrayOfLinks: ' + arrayOfLinks[i]);
        bodyID.appendChild(arrayOfLinks[i]);
      }, 1000 * i);
    }
  }
  fontLoader();

  useEffect(() => {
    // api call
    fetchData();
    // body styling
    document.body.style.margin = 0;
    document.body.style.backgroundColor = '#F7F9FD';
    document.body.style.overflow = 'overlay';
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <Router>
        <Layout googleFontsData={googleFontsData} />
      </Router>
    );
  }
}
