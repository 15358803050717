import React from 'react';

// import assets
import { ReactComponent as DarkmodeSun } from '../assets/icons/darkmode-sun.svg';
import { ReactComponent as DarkmodeMoon } from '../assets/icons/darkmode-moon.svg';

export default function DarkmodeToggle(props) {
  return (
    <div className="toggle-switch">
      <label className="label">
        <div className={props.darkmode ? 'toggle' : 'toggleDM'}>
          <input
            className="toggle-state"
            type="checkbox"
            name={'DarkmodeToggle'}
            id={'DarkmodeToggle'}
            onChange={() => props.toggleDarkmode()}
            defaultChecked={!props.darkmode}
          />
          <DarkmodeSun className="darkmodeSun" />
          <DarkmodeMoon className="darkmodeMoon" />
          <div className={props.darkmode ? 'indicator' : 'indicatorDM'}></div>
        </div>
      </label>
    </div>
  );
}
