import React from 'react';

export default function InputRange(props) {
  return (
    <div>
      <div className="flexRow">
        <h4 className={'adjustSizeHeading'}>{props.rangeHeading}</h4>
        <div
          id={props.rangeDisplayElementId}
          className={props.darkmode ? 'SizeValueDisplay' : 'SizeValueDisplayDM'}
        ></div>
      </div>
      <input
        onChange={() =>
          props.setFontValues(
            props.rangeElementId,
            props.rangeDisplayElementId,
            props.displayElementID
          )
        }
        className={props.darkmode ? 'inputRange' : 'inputRangeDM'}
        type="range"
        min="0"
        max="10"
        step="0.1"
        id={props.rangeElementId}
      />
    </div>
  );
}
