import React from 'react';

import Header from '../components/Header';

export default function About(props) {
  return (
    <div
      className={props.darkmode ? 'pageContainer-sub' : 'pageContainer-subDM'}
    >
      <div
        className={props.darkmode ? 'sideBarContainer' : 'sideBarContainerDM'}
      >
        <Header
          toggleDarkmode={props.toggleDarkmode}
          darkmode={props.darkmode}
        />
        <h1 className={props.darkmode ? 'subPageHeading' : 'subPageHeadingDM'}>
          About
        </h1>
      </div>
      <div
        className={
          props.darkmode
            ? 'subPageContentContainer'
            : 'subPageContentContainerDM'
        }
      >
        <div></div>
        <h2 className={props.darkmode ? 'subPageHeading' : 'subPageHeadingDM'}>
          About NotMyType
        </h2>
        <p>
          Ever since I started to design I have found working with fonts rather
          cumbersome. Sometimes you just want to try out a few combinations,
          without having to download and install them – that is what the
          NotMyType app was built for.
        </p>
        <p>
          NotMyType is a frontend only, serverless single page web application
          (SPA) built with React and based on the Google Fonts API. That means,
          there is no backend, no database and overall no strings attached.
        </p>
        <p>
          It was designed to grow as the API grows. Which means it will
          hopefully by itself become more usefull over time. If you want to
          contribute, or have further questions, please start{' '}
          <a href="/support">here</a>.
        </p>
      </div>
      <div
        className={props.darkmode ? 'googleAdsenseCont' : 'googleAdsenseContDM'}
      ></div>
    </div>
  );
}
