const CSSDataComplete = {
  // comment: '/* montserrat-regular - latin */',
  comment: '/* Your Styles */',
  selectorH1: 'h1 ',
  h1: {
    'font-family': '',
    'font-weight': '',
    'font-size': '',
    'text-align': '',
    'line-height': '',
    'margin-bottom': '',
  },
  selectorH2: 'h2 ',
  h2: {
    'font-family': '',
    'font-weight': '',
    'font-size': '',
    'text-align': '',
    'line-height': '',
    'margin-bottom': '',
  },
  selectorH3: 'h3 ',
  h3: {
    'font-family': '',
    'font-weight': '',
    'font-size': '',
    'text-align': '',
    'line-height': '',
    'margin-bottom': '',
  },
  selectorH4: 'h4 ',
  h4: {
    'font-family': '',
    'font-weight': '',
    'font-size': '',
    'text-align': '',
    'line-height': '',
    'margin-bottom': '',
  },
  selectorH5: 'h5 ',
  h5: {
    'font-family': '',
    'font-weight': '',
    'font-size': '',
    'text-align': '',
    'line-height': '',
    'margin-bottom': '',
  },
  selectorH6: 'h6 ',
  h6: {
    'font-family': '',
    'font-weight': '',
    'font-size': '',
    'text-align': '',
    'line-height': '',
    'margin-bottom': '',
  },
  selectorP: 'p ',
  p: {
    'font-family': '',
    'font-weight': '',
    'font-size': '',
    'text-align': '',
    'line-height': '',
    'margin-bottom': '',
  },
};
export default CSSDataComplete;

// css data computing object
export const cssSelectorsComplete = [
  {
    selector: 'h1',
    id: 'headingsH1',
  },
  {
    selector: 'h2',
    id: 'headingsH2',
  },
  {
    selector: 'h3',
    id: 'headingsH3',
  },
  {
    selector: 'h4',
    id: 'headingsH4',
  },
  {
    selector: 'h5',
    id: 'headingsH5',
  },
  {
    selector: 'h6',
    id: 'headingsH6',
  },
  {
    selector: 'p',
    id: 'bodyP',
  },
];
