import React from 'react';

export default function TypographySearchBar(props) {
  return (
    <div className="fontSearchBarCont">
      <input
        className={
          props.darkmode ? 'typographySearchbar' : 'typographySearchbarDM'
        }
        type="text"
        value={props.searchInput}
        onChange={props.changeSearchInput}
        placeholder={'Search font'}
      />
    </div>
  );
}
