import React from 'react';

import Header from '../components/Header';

export default function Contact(props) {
  return (
    <div
      className={props.darkmode ? 'pageContainer-sub' : 'pageContainer-subDM'}
    >
      <div
        className={props.darkmode ? 'sideBarContainer' : 'sideBarContainerDM'}
      >
        <Header
          toggleDarkmode={props.toggleDarkmode}
          darkmode={props.darkmode}
        />
        <h1 className={props.darkmode ? 'subPageHeading' : 'subPageHeadingDM'}>
          Contact
        </h1>
      </div>
      <div
        className={
          props.darkmode
            ? 'subPageContentContainer'
            : 'subPageContentContainerDM'
        }
      >
        <div></div>
        <h2 className={props.darkmode ? 'subPageHeading' : 'subPageHeadingDM'}>
          Feel free to drop a line
        </h2>
        <p>
          Please feel free to share your experience, feedback, or even ideas for
          future features with me. All are much appreciated and can be send to
          me{' '}
          <a href="mailto:info@design-berlin.net?subject=NotMyType Support">
            via Mail
          </a>
          .
        </p>
        <p>Thank you and happy designing!</p>
      </div>
      <div
        className={props.darkmode ? 'googleAdsenseCont' : 'googleAdsenseContDM'}
      ></div>
    </div>
  );
}
