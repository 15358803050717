import React, { useEffect } from 'react';
import { AutoSizer, List } from 'react-virtualized';

// import components
import TypographyFontItem from './TypographyFontItem';

export default function TypographyCategoryList(props) {
  // console.log('rendered TypographyCategoryList');

  function onClickEvents(itemFamily, itemFontWeight, itemFontStyle) {
    if (props.chooseFontsMode === 'headingsFont') {
      props.setHeadingsFontCallback(itemFamily, itemFontWeight, itemFontStyle);
    } else if (props.chooseFontsMode === 'bodyFont') {
      props.setBodyFontCallback(itemFamily, itemFontWeight, itemFontStyle);
    } else {
      return;
    }
    props.toggleChooseFontSidebar();
  }

  // filtering category list
  var filteredDataGoogleFonts = props.displayDataGoogleFonts;
  function filterCategoryList() {
    filteredDataGoogleFonts = props.displayDataGoogleFonts;
    if (props.filterStateCategoryList === 'all') {
      filteredDataGoogleFonts = props.displayDataGoogleFonts;
    } else {
      filteredDataGoogleFonts = props.displayDataGoogleFonts.filter(function (
        el
      ) {
        return el.fontCategory === props.filterStateCategoryList;
      });
    }
  }
  filterCategoryList();

  // search filter
  const filterBySearchInput = () => {
    filteredDataGoogleFonts = filteredDataGoogleFonts.filter((font) =>
      font.fontFamily.toLowerCase().includes(props.searchInput.toLowerCase())
    );
  };
  filterBySearchInput();

  // italic filter
  const filterByItalic = () => {
    if (!props.toggleItalic) {
      filteredDataGoogleFonts = filteredDataGoogleFonts.filter((font) =>
        font.fontStyle.toLowerCase().includes('regular')
      );
    } else {
      return null;
    }
  };
  filterByItalic();

  // creating list for react window
  const rowRenderer = ({ key, index, style }) => {
    return (
      <div key={key} style={style}>
        <TypographyFontItem
          darkmode={props.darkmode}
          item={filteredDataGoogleFonts[index]}
          onClickEvents={onClickEvents}
          chooseFontsMode={props.chooseFontsMode}
          changeHeadingsFontOnHover={props.changeHeadingsFontOnHover}
          changeHeadingsFontOnMouseLeave={props.changeHeadingsFontOnMouseLeave}
          changeBodyFontOnHover={props.changeBodyFontOnHover}
          changeBodyFontOnMouseLeave={props.changeBodyFontOnMouseLeave}
        />
      </div>
    );
  };

  useEffect(() => {
    props.checkForSearchResults();
  });

  return (
    <div
      className={
        props.darkmode ? 'chooseFontCategoryList' : 'chooseFontCategoryListDM'
      }
    >
      {props.noSearchResults ? (
        <span
          className={
            props.darkmode ? 'fontSearchBarFallback' : 'fontSearchBarFallbackDM'
          }
        >
          No matching fonts found.
        </span>
      ) : null}

      <AutoSizer>
        {({ height, width }) => (
          <List
            rowRenderer={rowRenderer}
            rowCount={filteredDataGoogleFonts.length}
            rowHeight={25}
            height={height}
            width={width}
          />
        )}
      </AutoSizer>
    </div>
  );
}
