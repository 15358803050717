import React from 'react';
import { useSpring, animated } from 'react-spring';

import InputRange from './InputRange';

// import assets
import { ReactComponent as DropDownArrow } from '../assets/icons/drop-down-arrow.svg';
import { ReactComponent as DropDownArrowWhite } from '../assets/icons/drop-down-arrow-w.svg';

export default function TypographyDashboardSpacing(props) {
  // console.log('rendered TypographyDashboardSpacing Component');

  // general animation
  const uiFadeOut = useSpring({
    opacity: props.showSpacingMenu ? 1 : 0.5,
  });

  const SpacingMenuAppear = useSpring({
    opacity: props.showSpacingMenu ? 1 : 0,
    height: props.showSpacingMenu ? 110 : 0,
    marginTop: props.showSpacingMenu ? '0.7rem' : '0',
  });

  const { y } = useSpring({
    y: props.showSpacingMenu ? 180 : 0,
  });

  return (
    <div className={'typographyDashboardCont'}>
      <animated.button
        onClick={() => {
          props.toggleSpacingMenu();
        }}
        style={uiFadeOut}
        className={props.darkmode ? 'dropDownButton' : 'dropDownButtonDM'}
      >
        Adjust Spacing
        <animated.div
          style={{
            transform: y.interpolate((y) => `rotateX(${y}deg)`),
            height: '1rem',
            position: 'relative',
            top: ' 1px',
          }}
        >
          {props.darkmode ? (
            <DropDownArrow className={'dropDownArrow'} />
          ) : (
            <DropDownArrowWhite className={'dropDownArrow'} />
          )}
        </animated.div>
      </animated.button>
      <animated.div style={SpacingMenuAppear}>
        <InputRange
          darkmode={props.darkmode}
          setFontValues={props.setLineHeightValues}
          rangeHeading={'P Line-Height –'}
          rangeElementId={'rangeInputGlobalLineHeight'}
          rangeDisplayElementId={'globalLineHeightDisplay'}
          displayElementID={'globalElements'}
        />
        <InputRange
          darkmode={props.darkmode}
          setFontValues={props.setBodyMarginBottomValues}
          rangeHeading={'P Margin-Bottom –'}
          rangeElementId={'rangeInputPMarginBottom'}
          rangeDisplayElementId={'pMarginBottomDisplay'}
          displayElementID={'globalElements2'}
        />
      </animated.div>
    </div>
  );
}
