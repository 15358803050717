import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { Prompt } from 'react-router';

import Header from '../components/Header';
import TypographyDashboard from '../components/TypographyDashboard';
import TypographyDisplay from '../components/TypographyDisplay';

// import data
import CSSDataBasic, {
  cssVarsAndProps,
  cssSelectorsBasic,
} from '../logic/CSSDataBasic';
import CSSDataComplete, {
  cssSelectorsComplete,
} from '../logic/CSSDataComplete';
import typographyDisplayDataBasic from '../logic/TypographyDisplayDataBasic';
import typographyDisplayDataBlock from '../logic/TypographyDisplayDataBlock';
import typographyDisplayDataComplete from '../logic/TypographyDisplayDataComplete';

export default function Typography(props) {
  // // headings fonts
  // //
  // choose and set fonts onClick
  const [headingsFontFamily, setHeadingsFont] = useState('Choose Font');
  const [headingsFontWeight, setHeadingsFontWeight] = useState('');
  const [headingsFontStyle, setHeadingsFontStyle] = useState('normal');
  function setHeadingsFontCallback(newFontFamily, newFontWeight, newFontStyle) {
    setHeadingsFont(newFontFamily);
    setHeadingsFontWeight(newFontWeight);
    setHeadingsFontStyle(newFontStyle);
    const headingsFontDisplay = document.getElementById(
      'headingsFontPickerDisplay'
    );
    headingsFontDisplay.style.fontFamily = newFontFamily;
    // set state for download
    setHeadingsFontFamilySet(true);
    // set headings
    let headings = document.getElementsByClassName(
      'typographyDisplayItemHeading'
    );
    for (var i = 0; i < headings.length; i++) {
      headings[i].style.fontFamily = newFontFamily;
    }
  }
  // set category list fonts by hover
  function changeHeadingsFontOnHover(e) {
    let fontFamilyToChangeTo = e.target.style.fontFamily;
    let fontWeightToChangeTo = e.target.style.fontWeight;
    let fontStyleToChangeTo = e.target.style.fontStyle;
    // set headings
    let headings = document.getElementsByClassName(
      'typographyDisplayItemHeading'
    );
    for (var i = 0; i < headings.length; i++) {
      headings[i].style.fontFamily = fontFamilyToChangeTo;
      headings[i].style.fontWeight = fontWeightToChangeTo;
      headings[i].style.fontStyle = fontStyleToChangeTo;
    }
  }
  // set category list fonts on mouseLeave
  function changeHeadingsFontOnMouseLeave() {
    // reset headings
    let headings = document.getElementsByClassName(
      'typographyDisplayItemHeading'
    );
    for (var i = 0; i < headings.length; i++) {
      headings[i].style.fontFamily = headingsFontFamily;
      headings[i].style.fontWeight = headingsFontWeight;
      headings[i].style.fontStyle = headingsFontStyle;
    }
  }
  // // body fonts
  // choose and set fonts onClick
  const [bodyFontFamily, setBodyFont] = useState('Choose Font');
  const [bodyFontSize, setBodyFontSize] = useState(1);
  const [bodyFontWeight, setBodyFontWeight] = useState('');
  const [bodyFontStyle, setBodyFontStyle] = useState('normal');
  function setBodyFontCallback(newFontFamily, newFontWeight, newFontStyle) {
    setBodyFont(newFontFamily);
    setBodyFontWeight(newFontWeight);
    setBodyFontStyle(newFontStyle);
    const bodyFontDisplay = document.getElementById('bodyFontPickerDisplay');
    bodyFontDisplay.style.fontFamily = newFontFamily;
    // set state for download
    setBodyFontFamilySet(true);
    // set body
    let paragraphs = document.getElementsByClassName(
      'typographyDisplayItemBody'
    );
    for (var i = 0; i < paragraphs.length; i++) {
      paragraphs[i].style.fontFamily = newFontFamily;
    }
  }
  // set category list fonts by hover
  function changeBodyFontOnHover(e) {
    let fontFamilyToChangeTo = e.target.style.fontFamily;
    let fontWeightToChangeTo = e.target.style.fontWeight;
    let fontStyleToChangeTo = e.target.style.fontStyle;
    // set paragraphs
    let paragraphs = document.getElementsByClassName(
      'typographyDisplayItemBody'
    );
    for (var i = 0; i < paragraphs.length; i++) {
      paragraphs[i].style.fontFamily = fontFamilyToChangeTo;
      paragraphs[i].style.fontWeight = fontWeightToChangeTo;
      paragraphs[i].style.fontStyle = fontStyleToChangeTo;
    }
  }
  // set category list fonts on mouseLeave
  function changeBodyFontOnMouseLeave() {
    // reset paragraphs
    let paragraphs = document.getElementsByClassName(
      'typographyDisplayItemBody'
    );
    for (var i = 0; i < paragraphs.length; i++) {
      paragraphs[i].style.fontFamily = bodyFontFamily;
      paragraphs[i].style.fontWeight = bodyFontWeight;
      paragraphs[i].style.fontStyle = bodyFontStyle;
    }
  }

  // // typographyDisplayData configuration
  // storing data in state
  const [typographyDisplayDataState, setTypographyDisplayDataState] = useState(
    typographyDisplayDataBasic
  );

  // // Layout
  // //
  // storing layout selection in state
  const [typographyLayoutSelection, setTypographyLayoutSelection] = useState(
    'Basic'
  );
  // // defining layout classes
  // //
  var layoutClasses;
  function setLayoutClasses() {
    switch (typographyLayoutSelection) {
      case 'Basic':
        return (layoutClasses = classNames(
          props.darkmode ? 'contentContainerBasic' : 'contentContainerBasicDM'
        ));
      case 'Block':
        return (layoutClasses = classNames(
          props.darkmode ? 'contentContainerBlock' : 'contentContainerBlockDM'
        ));
      case 'Complete':
        return (layoutClasses = classNames(
          props.darkmode
            ? 'contentContainerComplete'
            : 'contentContainerCompleteDM'
        ));
      default:
        return;
    }
  }
  useEffect(() => {
    setLayoutClasses();
    var element = document.getElementById('typographyContentContainer');
    element.className = '';
    element.classList.add(layoutClasses);
    // console.log('layoutClasses: ', layoutClasses);
  });
  // changing layouts
  function changeFontLayoutBasic() {
    setTypographyDisplayDataState(typographyDisplayDataBasic);
    setTypographyLayoutSelection('Basic');
  }
  function changeFontLayoutBlock() {
    setTypographyDisplayDataState(typographyDisplayDataBlock);
    setTypographyLayoutSelection('Block');
  }
  function changeFontLayoutComplete() {
    setTypographyDisplayDataState(typographyDisplayDataComplete);
    setTypographyLayoutSelection('Complete');
  }
  // set font styles on layout change
  function setFontStylesOnLayoutChange() {
    let headings = document.getElementsByClassName(
      'typographyDisplayItemHeading'
    );
    for (let i = 0; i < headings.length; i++) {
      headings[i].style.fontFamily = headingsFontFamily;
      headings[i].style.fontWeight = headingsFontWeight;
      headings[i].style.fontStyle = headingsFontStyle;
    }
    let h1 = document.getElementById('headingsH1');
    h1.style.fontSize = sizeRangeH1 + 'rem';
    if (typographyLayoutSelection === 'Complete') {
      let h2 = document.getElementById('headingsH2');
      h2.style.fontSize = sizeRangeH2 + 'rem';
      let h3 = document.getElementById('headingsH3');
      h3.style.fontSize = sizeRangeH3 + 'rem';
      let h4 = document.getElementById('headingsH4');
      h4.style.fontSize = sizeRangeH4 + 'rem';
      let h5 = document.getElementById('headingsH5');
      h5.style.fontSize = sizeRangeH5 + 'rem';
      let h6 = document.getElementById('headingsH6');
      h6.style.fontSize = sizeRangeH6 + 'rem';
    }
    let paragraphs = document.getElementsByClassName(
      'typographyDisplayItemBody'
    );
    for (let a = 0; a < paragraphs.length; a++) {
      paragraphs[a].style.fontFamily = bodyFontFamily;
      paragraphs[a].style.fontSize = bodyFontSize + 'rem';
      paragraphs[a].style.fontWeight = bodyFontWeight;
      paragraphs[a].style.fontStyle = bodyFontStyle;
    }
  }

  // // font sizes
  // //
  const [sizeRangeH1, setSizeRangeH1] = useState(2);
  const [sizeRangeH2, setSizeRangeH2] = useState(1.5);
  const [sizeRangeH3, setSizeRangeH3] = useState(1.17);
  const [sizeRangeH4, setSizeRangeH4] = useState(1);
  const [sizeRangeH5, setSizeRangeH5] = useState(0.83);
  const [sizeRangeH6, setSizeRangeH6] = useState(0.67);

  function setFontSizeValues(
    rangeElementId,
    rangeDisplayElementId,
    displayElementID
  ) {
    let fontSizeValue = document.getElementById(rangeElementId).value;
    document.getElementById(rangeDisplayElementId).innerHTML =
      fontSizeValue + ' rem';
    function setSizeOnChange() {
      switch (rangeElementId) {
        case 'rangeInputValH1':
          let h1 = document.getElementById(displayElementID);
          h1.style.fontSize = fontSizeValue + 'rem';
          setSizeRangeH1(fontSizeValue);
          break;
        case 'rangeInputValH2':
          let h2 = document.getElementById(displayElementID);
          h2.style.fontSize = fontSizeValue + 'rem';
          setSizeRangeH2(fontSizeValue);
          break;
        case 'rangeInputValH3':
          let h3 = document.getElementById(displayElementID);
          h3.style.fontSize = fontSizeValue + 'rem';
          setSizeRangeH3(fontSizeValue);
          break;
        case 'rangeInputValH4':
          let h4 = document.getElementById(displayElementID);
          h4.style.fontSize = fontSizeValue + 'rem';
          setSizeRangeH4(fontSizeValue);
          break;
        case 'rangeInputValH5':
          let h5 = document.getElementById(displayElementID);
          h5.style.fontSize = fontSizeValue + 'rem';
          setSizeRangeH5(fontSizeValue);
          break;
        case 'rangeInputValH6':
          let h6 = document.getElementById(displayElementID);
          h6.style.fontSize = fontSizeValue + 'rem';
          setSizeRangeH6(fontSizeValue);
          break;
        case 'rangeInputValP':
          let p = document.getElementsByClassName(displayElementID);
          let i;
          for (i = 0; i < p.length; i++) {
            p[i].style.fontSize = fontSizeValue + 'rem';
          }
          setBodyFontSize(fontSizeValue);
          break;
        default:
          return;
      }
    }
    setSizeOnChange();
  }

  // // initialize font size range values
  // //
  function initializeRangeValues() {
    // initialize h1
    document.getElementById('rangeInputValH1').value = sizeRangeH1;
    document.getElementById('h1SizeDisplay').innerHTML =
      document.getElementById('rangeInputValH1').value + ' rem';
    // initialize p
    document.getElementById('rangeInputValP').value = bodyFontSize;
    document.getElementById('pSizeDisplay').innerHTML =
      document.getElementById('rangeInputValP').value + ' rem';
    // Complete Layout conditional
    if (typographyLayoutSelection === 'Complete') {
      // initialize h2
      document.getElementById('rangeInputValH2').value = sizeRangeH2;
      document.getElementById('h2SizeDisplay').innerHTML =
        document.getElementById('rangeInputValH2').value + ' rem';
      // initialize h3
      document.getElementById('rangeInputValH3').value = sizeRangeH3;
      document.getElementById('h3SizeDisplay').innerHTML =
        document.getElementById('rangeInputValH3').value + ' rem';
      // initialize h4
      document.getElementById('rangeInputValH4').value = sizeRangeH4;
      document.getElementById('h4SizeDisplay').innerHTML =
        document.getElementById('rangeInputValH4').value + ' rem';
      // initialize h5
      document.getElementById('rangeInputValH5').value = sizeRangeH5;
      document.getElementById('h5SizeDisplay').innerHTML =
        document.getElementById('rangeInputValH5').value + ' rem';
      // initialize h6
      document.getElementById('rangeInputValH6').value = sizeRangeH6;
      document.getElementById('h6SizeDisplay').innerHTML =
        document.getElementById('rangeInputValH6').value + ' rem';
    }
  }
  useEffect(() => {
    initializeRangeValues();
  });

  // // alignment
  // //
  const [typographyAlignment, setTypographyAlignment] = useState('left');
  function setTypographyAlignmentForAll() {
    let elements = document.getElementsByClassName('typographyDisplayItem');
    let i;
    for (i = 0; i < elements.length; i++) {
      elements[i].style.textAlign = typographyAlignment;
    }
    let elementsDM = document.getElementsByClassName('typographyDisplayItemDM');
    let j;
    for (j = 0; j < elementsDM.length; j++) {
      elementsDM[j].style.textAlign = typographyAlignment;
    }
  }
  function setTypographyAlignmentCallback(newAlignment) {
    setTypographyAlignment(newAlignment);
  }
  useEffect(() => {
    setTypographyAlignmentForAll();
  });

  // // spacing
  // //
  // line height
  const [globalLineHeight, setGlobalLineHeight] = useState(1.2);
  function setLineHeightValues(
    rangeElementId,
    rangeDisplayElementId,
    displayElementID
  ) {
    // console.log('running setLineHeightValues');
    let lineHeightValue = document.getElementById(rangeElementId).value;
    setGlobalLineHeight(lineHeightValue);
    // console.log('lineHeightValue: ', lineHeightValue);
    document.getElementById(rangeDisplayElementId).innerHTML =
      lineHeightValue + ' rem';
    let elements = document.getElementsByClassName('typographyDisplayItemBody');
    let i;
    for (i = 0; i < elements.length; i++) {
      elements[i].style.lineHeight = lineHeightValue + 'rem';
    }
  }
  // initialize line height values
  function initializeLineHeightValues() {
    // initialize h1
    document.getElementById(
      'rangeInputGlobalLineHeight'
    ).value = globalLineHeight;
    document.getElementById('globalLineHeightDisplay').innerHTML =
      document.getElementById('rangeInputGlobalLineHeight').value + ' rem';
    let elements = document.getElementsByClassName('typographyDisplayItemBody');
    let i;
    for (i = 0; i < elements.length; i++) {
      elements[i].style.lineHeight = globalLineHeight + 'rem';
    }
  }
  useEffect(() => {
    initializeLineHeightValues();
  });
  // // margin bottom
  const [bodyMarginBottom, setBodyMarginBottom] = useState(2.2);
  function setBodyMarginBottomValues(
    rangeElementId,
    rangeDisplayElementId,
    displayElementID
  ) {
    // console.log('running setBodyMarginBottomValues');
    let marginBottomValue = document.getElementById(rangeElementId).value;
    setBodyMarginBottom(marginBottomValue);
    // console.log('marginBottomValue: ', marginBottomValue);
    // console.log('bodyMarginBottom: ', bodyMarginBottom);
    document.getElementById(rangeDisplayElementId).innerHTML =
      marginBottomValue + ' rem';
    let elements = document.getElementsByClassName('typographyDisplayItemBody');
    // console.log('elements: ', elements);
    let i;
    for (i = 0; i < elements.length; i++) {
      elements[i].style.marginBottom = marginBottomValue + 'rem';
    }
  }
  // initialize line height values
  function initializeMarginBottomValues() {
    // initialize h1
    document.getElementById('rangeInputPMarginBottom').value = bodyMarginBottom;
    document.getElementById('pMarginBottomDisplay').innerHTML =
      document.getElementById('rangeInputPMarginBottom').value + ' rem';
    let elements = document.getElementsByClassName('typographyDisplayItemBody');
    let i;
    for (i = 0; i < elements.length; i++) {
      elements[i].style.marginBottom = bodyMarginBottom + 'rem';
    }
  }
  useEffect(() => {
    initializeMarginBottomValues();
  });

  // let fontSizeValue = document.getElementById(rangeElementId).value;
  // document.getElementById(rangeDisplayElementId).innerHTML =
  //   fontSizeValue + ' rem';

  // // css data download
  // //
  const [cssDataBasic] = useState(CSSDataBasic);
  const [cssDataComplete] = useState(CSSDataComplete);
  // console.log('cssData: ', cssData);
  // css style collection
  function collectCssStyles() {
    // console.log('ran collectCssStyles');
    if (typographyLayoutSelection === 'Complete') {
      cssSelectorsComplete.forEach((htmlItem) => {
        // get all selectors and items by id
        // console.log('cssSelectors item.id: ', htmlItem.id);
        let itemHtmlElement = document.getElementById(htmlItem.id);
        // console.log('itemHtmlElement: ', itemHtmlElement);
        let itemHtmlSelector = htmlItem.selector;
        // loop cssVarsAndProps
        let i = 1;
        cssVarsAndProps.forEach((styleItem) => {
          // console.log(styleItem.variable);
          i = i + 1;
          let itemVariable = styleItem.variable;
          // handling font family
          if (styleItem.variable === 'FontFamily') {
            itemVariable = window
              .getComputedStyle(itemHtmlElement)
              .getPropertyValue(styleItem.property);
            if (itemVariable !== 'Choose Font') {
              itemVariable = "'" + itemVariable + "'";
            }
            // console.log('itemVariableProp: ', itemVariable);
            cssDataComplete[itemHtmlSelector][styleItem.property] =
              itemVariable + '; ';
          } else if (styleItem.variable === 'FontSize') {
            // turning pixel into rem for font-size
            itemVariable = window
              .getComputedStyle(itemHtmlElement)
              .getPropertyValue(styleItem.property);
            itemVariable = itemVariable.replace(/px+/g, '');
            itemVariable = itemVariable / 16;
            itemVariable = itemVariable + 'rem';
            // console.log('itemVariableProp: ', itemVariable);
            cssDataComplete[itemHtmlSelector][styleItem.property] =
              itemVariable + '; ';
          } else if (styleItem.variable === 'LineHeight') {
            // turning pixel into rem for line-height
            itemVariable = window
              .getComputedStyle(itemHtmlElement)
              .getPropertyValue(styleItem.property);
            // console.log('itemVariableProp: ', itemVariable);
            if (itemVariable === 'normal') {
              cssDataComplete[itemHtmlSelector][styleItem.property] =
                itemVariable + '; ';
              return;
            } else {
              itemVariable = itemVariable.replace(/px+/g, '');
              itemVariable = itemVariable / 16;
              itemVariable = itemVariable + 'rem';
            }
            // console.log('itemVariableProp: ', itemVariable);
            cssDataComplete[itemHtmlSelector][styleItem.property] =
              itemVariable + '; ';
          } else {
            itemVariable = window
              .getComputedStyle(itemHtmlElement)
              .getPropertyValue(styleItem.property);
            // console.log('itemVariableProp: ', itemVariable);
            cssDataComplete[itemHtmlSelector][styleItem.property] =
              itemVariable + '; ';
          }
        });
      });
    } else {
      cssSelectorsBasic.forEach((htmlItem) => {
        // get all selectors and items by id
        // console.log('cssSelectors item.id: ', htmlItem.id);
        let itemHtmlElement = document.getElementById(htmlItem.id);
        // console.log('itemHtmlElement: ', itemHtmlElement);
        let itemHtmlSelector = htmlItem.selector;
        // loop cssVarsAndProps
        let i = 1;
        cssVarsAndProps.forEach((styleItem) => {
          // console.log(styleItem.variable);
          i = i + 1;
          let itemVariable = styleItem.variable;
          // turning pixel into rem for font-size
          if (styleItem.variable === 'FontFamily') {
            itemVariable = window
              .getComputedStyle(itemHtmlElement)
              .getPropertyValue(styleItem.property);
            if (itemVariable !== 'Choose Font') {
              itemVariable = "'" + itemVariable + "'";
            }
            // console.log('itemVariableProp: ', itemVariable);
            cssDataBasic[itemHtmlSelector][styleItem.property] =
              itemVariable + '; ';
          } else if (styleItem.variable === 'FontSize') {
            itemVariable = window
              .getComputedStyle(itemHtmlElement)
              .getPropertyValue(styleItem.property);
            itemVariable = itemVariable.replace(/px+/g, '');
            itemVariable = itemVariable / 16;
            itemVariable = itemVariable + 'rem';
            // console.log('itemVariableProp: ', itemVariable);
            cssDataBasic[itemHtmlSelector][styleItem.property] =
              itemVariable + '; ';
          } else if (styleItem.variable === 'LineHeight') {
            // turning pixel into rem for line-height
            itemVariable = window
              .getComputedStyle(itemHtmlElement)
              .getPropertyValue(styleItem.property);
            // console.log('itemVariableProp: ', itemVariable);
            if (itemVariable === 'normal') {
              cssDataBasic[itemHtmlSelector][styleItem.property] =
                itemVariable + '; ';
              return;
            } else {
              itemVariable = itemVariable.replace(/px+/g, '');
              itemVariable = itemVariable / 16;
              itemVariable = itemVariable + 'rem';
            }
            // console.log('itemVariableProp: ', itemVariable);
            cssDataBasic[itemHtmlSelector][styleItem.property] =
              itemVariable + '; ';
          } else {
            itemVariable = window
              .getComputedStyle(itemHtmlElement)
              .getPropertyValue(styleItem.property);
            // console.log('itemVariableProp: ', itemVariable);
            cssDataBasic[itemHtmlSelector][styleItem.property] =
              itemVariable + '; ';
          }
        });
      });
    }
    // console.log('cssDataBasic: ', cssDataBasic);
    // console.log('cssDataComplete: ', cssDataComplete);
  }
  useEffect(() => {
    collectCssStyles();
  });

  function cssDownloadTrigger(fileName) {
    collectCssStyles();
    // console.log('cssData: ', cssData);
    let cssString;
    if (typographyLayoutSelection === 'Complete') {
      cssString = JSON.stringify(Object.values(cssDataComplete));
    } else {
      cssString = JSON.stringify(Object.values(cssDataBasic));
    }
    // removing square brackets
    // cssString = cssString.replace(/[\[\]']+/g, '');
    // removing first and last curly brakets
    cssString = cssString.slice(1, -1);
    // removing parenthesis
    cssString = cssString.replace(/"+/g, '');
    // removing commas
    cssString = cssString.replace(/,+/g, '');
    // removing colon after Elements
    // cssString = cssString.replace(/\.[^h1]+$/, '');
    // removing backlashes
    cssString = cssString.replace(/\\/g, '');
    // console.log('running cssDownloadTrigger');
    // console.log('cssString: ', cssString);
    let link = document.createElement('a');
    link.setAttribute(
      'href',
      'data:application/download;charset=utf-8,' + encodeURIComponent(cssString)
    );
    link.setAttribute('download', fileName + '.css');

    if (document.createEvent) {
      let event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      link.dispatchEvent(event);
    } else {
      link.click();
    }
  }

  // // font download
  // //
  const [headingsFontFamilySet, setHeadingsFontFamilySet] = useState(false);
  const [bodyFontFamilySet, setBodyFontFamilySet] = useState(false);
  // console.log('headingsFontFamilySet: ', headingsFontFamilySet);
  // console.log('bodyFontFamilySet: ', bodyFontFamilySet);
  const [headingsFontFamilySetUrls, setHeadingsFontFamilySetUrls] = useState(
    []
  );
  const [bodyFontFamilySetUrls, setBodyFontFamilySetUrls] = useState([]);
  // console.log('headingsFontFamilySetUrls: ', headingsFontFamilySetUrls);
  // console.log('bodyFontFamilySetUrls: ', bodyFontFamilySetUrls);
  function prepareFontsForDownload() {
    // getting font urls
    if (headingsFontFamilySet) {
      let headingsResult = props.googleFontsData.find((selectedFontFamily) => {
        return selectedFontFamily.family === headingsFontFamily;
      });
      let headingsFontUrls = Object.values(headingsResult.files);
      if (
        JSON.stringify(headingsFontFamilySetUrls) !==
        JSON.stringify(headingsFontUrls)
      ) {
        setHeadingsFontFamilySetUrls(Object.values(headingsResult.files));
      }
    }
    if (bodyFontFamilySet) {
      let bodyResult = props.googleFontsData.find((selectedFontFamily) => {
        return selectedFontFamily.family === bodyFontFamily;
      });
      let bodyFontUrls = Object.values(bodyResult.files);
      if (
        JSON.stringify(bodyFontFamilySetUrls) !== JSON.stringify(bodyFontUrls)
      ) {
        setBodyFontFamilySetUrls(Object.values(bodyResult.files));
      }
    }
  }
  prepareFontsForDownload();
  // // axios http requests
  // headings
  function headingsFontDownloadTrigger() {
    if (headingsFontFamilySet) {
      // prepare font names
      let headingsResult = props.googleFontsData.find((selectedFontFamily) => {
        return selectedFontFamily.family === headingsFontFamily;
      });
      headingsResult = headingsResult.files;
      // console.log('headingsResult: ', headingsResult);

      const urls = Object.keys(headingsResult);
      // console.log('urls: ', urls);
      // iterate over object
      urls.forEach((url, i) => {
        setTimeout(() => {
          // console.log('running axios');
          let fontWeight = `${url}`;
          axios({
            url: `${headingsResult[url]}`, //your url
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            // replacing http with https
            url = url.replace(/^blob:http:\/\//i, 'blob:https://');
            const link = document.createElement('a');
            link.href = url;
            console.log('url: ', url);
            link.setAttribute(
              'download',
              headingsFontFamily + '-' + fontWeight + '.ttf'
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        }, i * 100);
      });
    }
  }
  // body
  function bodyFontDownloadTrigger() {
    if (bodyFontFamilySet) {
      // prepare font names
      let bodyResult = props.googleFontsData.find((selectedFontFamily) => {
        return selectedFontFamily.family === bodyFontFamily;
      });
      bodyResult = bodyResult.files;
      // console.log('bodyResult: ', bodyResult);

      const urls = Object.keys(bodyResult);
      // console.log('urls: ', urls);
      // iterate over object
      urls.forEach((url, i) => {
        setTimeout(() => {
          // console.log('running axios');
          let fontWeight = `${url}`;
          axios({
            url: `${bodyResult[url]}`, //your url
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            // replacing http with https
            url = url.replace(/^blob:http:\/\//i, 'blob:https://');
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              bodyFontFamily + '-' + fontWeight + '.ttf'
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        }, i * 100);
      });
    }
  }

  // // warning before leaving page
  window.onbeforeunload = function () {
    return 'Are you sure you want to leave?';
  };

  return (
    <div className={props.darkmode ? 'pageContainer' : 'pageContainerDM'}>
      <Prompt message="You have unsaved changes, are you sure you want to leave?" />
      <div
        className={props.darkmode ? 'sideBarContainer' : 'sideBarContainerDM'}
      >
        <Header
          toggleDarkmode={props.toggleDarkmode}
          darkmode={props.darkmode}
        />
        <TypographyDashboard
          darkmode={props.darkmode}
          headingsFontFamily={headingsFontFamily}
          headingsFontWeight={headingsFontWeight}
          bodyFontFamily={bodyFontFamily}
          bodyFontWeight={bodyFontWeight}
          googleFontsData={props.googleFontsData}
          setHeadingsFontCallback={setHeadingsFontCallback}
          setBodyFontCallback={setBodyFontCallback}
          changeHeadingsFontOnHover={changeHeadingsFontOnHover}
          changeHeadingsFontOnMouseLeave={changeHeadingsFontOnMouseLeave}
          changeBodyFontOnHover={changeBodyFontOnHover}
          changeBodyFontOnMouseLeave={changeBodyFontOnMouseLeave}
          changeFontLayoutBasic={changeFontLayoutBasic}
          changeFontLayoutBlock={changeFontLayoutBlock}
          changeFontLayoutComplete={changeFontLayoutComplete}
          headingsFontDownloadTrigger={headingsFontDownloadTrigger}
          bodyFontDownloadTrigger={bodyFontDownloadTrigger}
          cssDownloadTrigger={cssDownloadTrigger}
          sizeRangeH1={sizeRangeH1}
          setFontSizeValues={setFontSizeValues}
          setLineHeightValues={setLineHeightValues}
          setBodyMarginBottomValues={setBodyMarginBottomValues}
          typographyLayoutSelection={typographyLayoutSelection}
          setTypographyAlignmentCallback={setTypographyAlignmentCallback}
        />
      </div>
      <div id={'typographyContentContainer'}>
        <TypographyDisplay
          darkmode={props.darkmode}
          typographyDisplayDataState={typographyDisplayDataState}
          typographyLayoutSelection={typographyLayoutSelection}
          setFontStylesOnLayoutChange={setFontStylesOnLayoutChange}
        />
      </div>
      <div
        className={props.darkmode ? 'googleAdsenseCont' : 'googleAdsenseContDM'}
      ></div>
    </div>
  );
}
