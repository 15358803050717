import React, { useState } from 'react';
import { animated } from 'react-spring';

// import components
import TypographyCategoryList from './TypographyCategoryList';
import TypographySearchBar from './TypographySearchBar';
import TpographyFontCategoryButton from './TypographyFontCategoryButton';
import TypographyItalicCheckbox from './TypographyItalicCheckbox';

// import assets
import { ReactComponent as CloseIcon } from '../assets/icons/close-icon.svg';
import { ReactComponent as CloseIconWhite } from '../assets/icons/close-icon-w.svg';

// import styles
import '../styles/styles.scss';

export default function TypographyChooseFontsSidebar(props) {
  // console.log('rendered TypographyChooseFontsSidebar');

  // setup font category buttons
  const fontCategoryButtons = [
    {
      name: 'All',
      category: 'all',
    },
    {
      name: 'Sans',
      category: 'sans-serif',
    },
    {
      name: 'Serif',
      category: 'serif',
    },
    {
      name: 'Display',
      category: 'display',
    },
    {
      name: 'Script',
      category: 'handwriting',
    },
    {
      name: 'Mono',
      category: 'monospace',
    },
  ];

  // filter data for category tabs
  const [filterStateCategoryList, setFilterStateCategoryList] = useState('all');
  function setFilterStateCategoryListFunction(filteredCategory) {
    setFilterStateCategoryList(filteredCategory);
    // console.log(filterStateCategoryList);
  }

  // italic filter
  const [toggleItalic, setToggleItalic] = useState(false);
  // // choose fonts menu
  function toggleChooseFontsItalic() {
    setToggleItalic(!toggleItalic);
  }

  // prepare data for category list
  var displayDataGoogleFonts = [];

  prepareDataForDisplay();

  function prepareDataForDisplay() {
    props.googleFontsData.forEach((item) => {
      // fetch font variants
      let fontVariants = item.variants;
      // loop over all variants
      fontVariants.forEach(function (fontVariantItem) {
        // setting the displayName
        let rawDataGoogleFonts = item.family + ' ' + fontVariantItem;
        // removing space before 'italic'
        modifyFontObjects(rawDataGoogleFonts);
        function modifyFontObjects(rawDataGoogleFonts) {
          let fontItemWithSpace = rawDataGoogleFonts.replace(
            '0italic',
            '0 italic'
          );
          // extracting the fontWeight as number and joining to string
          if (fontVariantItem.includes('0')) {
            var extractedFontWeight = fontVariantItem.match(/\d/g);
            extractedFontWeight = extractedFontWeight.join('');
          }
          // checking for fontStyle conditional
          let isItalic = fontItemWithSpace.includes('italic');
          // create object for font array
          let FontObject = {
            displayName: fontItemWithSpace,
            fontFamily: item.family,
            fontWeight: extractedFontWeight ? extractedFontWeight : '400',
            fontStyle: isItalic ? 'italic' : 'regular',
            fontCategory: item.category,
          };
          // pushing objects to font array
          displayDataGoogleFonts.push(FontObject);
        }
      });
    });
  }

  // filtering data for search bar
  const [searchInput, setSearchInput] = useState('');
  const changeSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  // set no results placeholder
  const [noSearchResults, setNoSearchResults] = useState(false);
  function checkForSearchResults() {
    let resultContainer = document.getElementsByClassName('typographyfontItem');
    let resultContainerDM = document.getElementsByClassName(
      'typographyFontItemDM'
    );
    if (resultContainer.length === 0 && resultContainerDM.length === 0) {
      setNoSearchResults(true);
    } else if (resultContainer.length > 0 || resultContainerDM.length > 0) {
      setNoSearchResults(false);
    }
  }

  // set active font category button
  const [activeButtonState, setActiveButtonState] = useState('All');
  function setActiveButtonFunction(button) {
    setActiveButtonState(button);
    // console.log(activeButtonState);
  }

  return (
    <animated.nav
      className={props.darkmode ? 'chooseFontsSidebar' : 'chooseFontsSidebarDM'}
    >
      <div className="chooseFontsSidebarDashboard">
        <div
          onClick={() => {
            props.toggleChooseFontSidebar();
          }}
          className={
            props.darkmode ? 'sidebarBackButton' : 'sidebarBackButtonDM'
          }
        >
          {props.darkmode ? (
            <CloseIcon className="sidebarBackButtonIcon" />
          ) : (
            <CloseIconWhite className="sidebarBackButtonIcon" />
          )}
        </div>
        <div className="fontCategoryButtonContDashboard">
          {fontCategoryButtons.map((button) => {
            return (
              <TpographyFontCategoryButton
                darkmode={props.darkmode}
                key={button.name}
                name={button.name}
                setFilterStateCategoryListFunction={
                  setFilterStateCategoryListFunction
                }
                buttonFontCategory={button.category}
                activeButtonState={activeButtonState}
                setActiveButtonFunction={setActiveButtonFunction}
              />
            );
          })}
        </div>
        <div className="fontSearchBarAndItalicCont">
          <TypographyItalicCheckbox
            darkmode={props.darkmode}
            toggleChooseFontsItalic={toggleChooseFontsItalic}
          />
          <TypographySearchBar
            darkmode={props.darkmode}
            searchInput={searchInput}
            changeSearchInput={changeSearchInput}
          />
        </div>
      </div>
      <TypographyCategoryList
        darkmode={props.darkmode}
        googleFontsData={props.googleFontsData}
        displayDataGoogleFonts={displayDataGoogleFonts}
        chooseFontsMode={props.chooseFontsMode}
        setHeadingsFontCallback={props.setHeadingsFontCallback}
        setBodyFontCallback={props.setBodyFontCallback}
        changeHeadingsFontOnHover={props.changeHeadingsFontOnHover}
        changeHeadingsFontOnMouseLeave={props.changeHeadingsFontOnMouseLeave}
        changeBodyFontOnHover={props.changeBodyFontOnHover}
        changeBodyFontOnMouseLeave={props.changeBodyFontOnMouseLeave}
        toggleChooseFontSidebar={props.toggleChooseFontSidebar}
        filterStateCategoryList={filterStateCategoryList}
        searchInput={searchInput}
        toggleItalic={toggleItalic}
        noSearchResults={noSearchResults}
        checkForSearchResults={checkForSearchResults}
      />
    </animated.nav>
  );
}
