import React, { useState } from 'react';

// import assets
import { ReactComponent as CheckboxTick } from '../assets/icons/checkbox-tick.svg';
import { ReactComponent as CheckboxTickWhite } from '../assets/icons/checkbox-tick-w.svg';

export default function TypographyItalicCheckbox(props) {
  // checked state
  const [checkboxIsChecked, setCheckboxIsChecked] = useState('false');
  function toggleCheckboxIsChecked() {
    setCheckboxIsChecked(!checkboxIsChecked);
  }

  function onClickEvents() {
    toggleCheckboxIsChecked();
    props.toggleChooseFontsItalic();
  }

  return (
    <div className={'checkBoxCont'}>
      <button
        className={props.darkmode ? 'checkBoxInput' : 'checkBoxInputDM'}
        name="isGoing"
        type="checkbox"
        checked={null}
        onClick={() => {
          onClickEvents();
        }}
      >
        {props.darkmode ? (
          !checkboxIsChecked ? (
            <CheckboxTick className={'checkBoxTick'} />
          ) : null
        ) : !checkboxIsChecked ? (
          <CheckboxTickWhite className={'checkBoxTick'} />
        ) : null}
      </button>
      {props.darkmode ? (
        <span
          className={
            !checkboxIsChecked ? 'checkBoxLabelActive' : 'checkBoxLabel'
          }
        >
          Italic
        </span>
      ) : (
        <span
          className={
            !checkboxIsChecked ? 'checkBoxLabelActiveDM' : 'checkBoxLabelDM'
          }
        >
          Italic
        </span>
      )}
    </div>
  );
}
