import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import TypographyAlignButton from './TypographyAlignButton';

// import assets
import { ReactComponent as DropDownArrow } from '../assets/icons/drop-down-arrow.svg';
import { ReactComponent as DropDownArrowWhite } from '../assets/icons/drop-down-arrow-w.svg';
import { ReactComponent as AlignmentLeft } from '../assets/icons/alignment-left-b.svg';
import { ReactComponent as AlignmentLeftWhite } from '../assets/icons/alignment-left-w.svg';
import { ReactComponent as AlignmentCenter } from '../assets/icons/alignment-center-b.svg';
import { ReactComponent as AlignmentCenterWhite } from '../assets/icons/alignment-center-w.svg';
import { ReactComponent as AlignmentRight } from '../assets/icons/alignment-right-b.svg';
import { ReactComponent as AlignmentRightWhite } from '../assets/icons/alignment-right-w.svg';

export default function TypographyDashboardAlign(props) {
  // console.log('rendered TypographyDashboardAlign Component');

  // general animation
  const uiFadeOut = useSpring({
    opacity: props.showAlignMenu ? 1 : 0.5,
  });

  const AlignMenuAppear = useSpring({
    opacity: props.showAlignMenu ? 1 : 0,
    height: props.showAlignMenu ? 40 : 0,
    marginTop: props.showAlignMenu ? '0.7rem' : '0.2rem',
  });

  const { y } = useSpring({
    y: props.showAlignMenu ? 180 : 0,
  });

  // set active align button
  const [activeButtonState, setActiveButtonState] = useState('left');
  function setActiveButtonFunction(button) {
    setActiveButtonState(button);
    // console.log(activeButtonState);
  }

  return (
    <div className={'typographyDashboardCont'}>
      <animated.button
        onClick={() => {
          props.toggleAlignMenu();
        }}
        style={uiFadeOut}
        className={props.darkmode ? 'dropDownButton' : 'dropDownButtonDM'}
      >
        Adjust Alignment
        <animated.div
          style={{
            transform: y.interpolate((y) => `rotateX(${y}deg)`),
            height: '1rem',
            position: 'relative',
            top: ' 1px',
          }}
        >
          {props.darkmode ? (
            <DropDownArrow className={'dropDownArrow'} />
          ) : (
            <DropDownArrowWhite className={'dropDownArrow'} />
          )}
        </animated.div>
      </animated.button>
      <animated.div style={AlignMenuAppear}>
        <div className="flexRow">
          <TypographyAlignButton
            darkmode={props.darkmode}
            name={'left'}
            icon={
              props.darkmode ? (
                <AlignmentLeft className={'alginmentIcon'} />
              ) : (
                <AlignmentLeftWhite className={'alginmentIcon'} />
              )
            }
            activeButtonState={activeButtonState}
            setActiveButtonFunction={setActiveButtonFunction}
            setTypographyAlignmentCallback={
              props.setTypographyAlignmentCallback
            }
          />
          <TypographyAlignButton
            darkmode={props.darkmode}
            name={'center'}
            icon={
              props.darkmode ? (
                <AlignmentCenter className={'alginmentIcon'} />
              ) : (
                <AlignmentCenterWhite className={'alginmentIcon'} />
              )
            }
            activeButtonState={activeButtonState}
            setActiveButtonFunction={setActiveButtonFunction}
            setTypographyAlignmentCallback={
              props.setTypographyAlignmentCallback
            }
          />
          <TypographyAlignButton
            darkmode={props.darkmode}
            name={'right'}
            icon={
              props.darkmode ? (
                <AlignmentRight className={'alginmentIcon'} />
              ) : (
                <AlignmentRightWhite className={'alginmentIcon'} />
              )
            }
            activeButtonState={activeButtonState}
            setActiveButtonFunction={setActiveButtonFunction}
            setTypographyAlignmentCallback={
              props.setTypographyAlignmentCallback
            }
          />
        </div>
      </animated.div>
    </div>
  );
}
