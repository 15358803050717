import React from 'react';
import { useSpring, animated } from 'react-spring';

// import assets
import { ReactComponent as DropDownArrow } from '../assets/icons/drop-down-arrow.svg';
import { ReactComponent as DropDownArrowWhite } from '../assets/icons/drop-down-arrow-w.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download-icon-b.svg';
import { ReactComponent as DownloadIconWhite } from '../assets/icons/download-icon-w.svg';

export default function TypographyDashboardDownload(props) {
  // console.log('rendered TypographyDashboardDownload Component');

  // general animation
  const uiFadeOut = useSpring({
    opacity: props.showDownloadMenu ? 1 : 0.5,
  });

  const downloadMenuAppear = useSpring({
    opacity: props.showDownloadMenu ? 1 : 0,
    height: props.showDownloadMenu ? 130 : 0,
    marginTop: props.showDownloadMenu ? '0.8rem' : '0',
  });

  const { y } = useSpring({
    y: props.showDownloadMenu ? 180 : 0,
  });

  return (
    <div className="typographyDashboardCont">
      <animated.button
        onClick={() => {
          props.toggleDownloadMenu();
        }}
        style={uiFadeOut}
        className={props.darkmode ? 'dropDownButton' : 'dropDownButtonDM'}
      >
        Download Fonts
        <animated.div
          style={{
            transform: y.interpolate((y) => `rotateX(${y}deg)`),
            height: '1rem',
            position: 'relative',
            top: ' 1px',
          }}
        >
          {props.darkmode ? (
            <DropDownArrow className={'dropDownArrow'} />
          ) : (
            <DropDownArrowWhite className={'dropDownArrow'} />
          )}
        </animated.div>
      </animated.button>

      <animated.div style={downloadMenuAppear}>
        <h4 className={'chooseFontHeading'}>Headings Font-Family</h4>
        <div
          className={
            props.darkmode ? 'fontDownloadDisplay' : 'fontDownloadDisplayDM'
          }
          onClick={() => props.headingsFontDownloadTrigger()}
        >
          <span style={{ fontWeight: props.headingsFontWeight }}>
            {props.headingsFontFamily}
          </span>
          {props.darkmode ? (
            <DownloadIcon className={'downloadIcon'} />
          ) : (
            <DownloadIconWhite className={'downloadIcon'} />
          )}
        </div>

        <h4 className="chooseFontHeading">Body Font-Family</h4>
        <div
          className={
            props.darkmode ? 'fontDownloadDisplay' : 'fontDownloadDisplayDM'
          }
          onClick={() => props.bodyFontDownloadTrigger()}
        >
          <span style={{ fontWeight: props.bodyFontWeight }}>
            {props.bodyFontFamily}
          </span>
          {props.darkmode ? (
            <DownloadIcon className={'downloadIcon'} />
          ) : (
            <DownloadIconWhite className={'downloadIcon'} />
          )}
        </div>
      </animated.div>
    </div>
  );
}
