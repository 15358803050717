import React, { useState } from 'react';

// import components
import NavSidebar from './NavSidebar';
import DarkmodeToggle from './DarkmodeToggle';

// import assets
import { ReactComponent as LogoBlack } from '../assets/icons/logo-s.svg';
import { ReactComponent as LogoWhite } from '../assets/icons/logo-w.svg';
import { ReactComponent as BurgerMenuIconBlack } from '../assets/icons/burger-menu-icon-s.svg';
import { ReactComponent as BurgerMenuIconWhite } from '../assets/icons/burger-menu-icon-w.svg';
import { ReactComponent as DarkmodeSun } from '../assets/icons/darkmode-sun.svg';
import { ReactComponent as DarkmodeMoon } from '../assets/icons/darkmode-moon.svg';

export default function Header(props) {
  const [showNavSidebar, setShowNavSidebar] = useState(false);

  function toggleNavSidebar() {
    setShowNavSidebar(!showNavSidebar);
  }

  return (
    <div className="headerContainer">
      <NavSidebar
        showNavSidebar={showNavSidebar}
        toggleNavSidebar={toggleNavSidebar}
      />
      <div
        className={props.darkmode ? 'burgerMenuCont' : 'burgerMenuContDM'}
        onClick={() => toggleNavSidebar()}
      >
        {props.darkmode ? (
          <BurgerMenuIconBlack className="burgerMenuIcon" />
        ) : (
          <BurgerMenuIconWhite className="burgerMenuIcon" />
        )}
      </div>
      <DarkmodeToggle
        darkmodeSun={<DarkmodeSun />}
        darkmodeMoon={<DarkmodeMoon />}
        toggleDarkmode={props.toggleDarkmode}
        darkmode={props.darkmode}
      />
      {props.darkmode ? (
        <LogoBlack className="logoBlack" />
      ) : (
        <LogoWhite className="logoBlack" />
      )}
    </div>
  );
}
