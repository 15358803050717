import React, { useState } from 'react';
import { BrowserRouter as Switch, Route } from 'react-router-dom';

import LoadingScreen from './LoadingScreen';
import GoogleFontsDialog from './GoogleFontsDialog';

// import screens
import Typography from '../screens/Typography';
import Support from '../screens/Support';
import About from '../screens/About';
import Contact from '../screens/Contact';

export default function Layout(props) {
  // console.log('rendered Layout Component');

  // // darkmode
  // //
  const [darkmode, setDarkmode] = useState('false');
  function toggleDarkmode() {
    setDarkmode(!darkmode);
  }

  // loading screen logic
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  function switchOffLoadingScreen() {
    setShowLoadingScreen(false);
  }

  // google dialog logic
  const [showGoogleDialog, setShowGoogleDialog] = useState(true);
  function switchOffGoogleDialog() {
    setShowGoogleDialog(false);
  }

  if (!showLoadingScreen && !showGoogleDialog) {
    return (
      <Switch>
        <Route activeClassName="is-active" exact path="/">
          <Typography
            googleFontsData={props.googleFontsData}
            toggleDarkmode={toggleDarkmode}
            darkmode={darkmode}
          />
        </Route>
        <Route path="/support">
          <Support toggleDarkmode={toggleDarkmode} darkmode={darkmode} />
        </Route>
        <Route path="/about">
          <About toggleDarkmode={toggleDarkmode} darkmode={darkmode} />
        </Route>
        <Route path="/contact">
          <Contact toggleDarkmode={toggleDarkmode} darkmode={darkmode} />
        </Route>
      </Switch>
    );
  } else if (!showGoogleDialog) {
    return (
      <LoadingScreen
        googleFontsData={props.googleFontsData}
        showLoadingScreen={showLoadingScreen}
        switchOffLoadingScreen={switchOffLoadingScreen}
      />
    );
  } else {
    return (
      <GoogleFontsDialog
        googleFontsData={props.googleFontsData}
        showGoogleDialog={showGoogleDialog}
        switchOffGoogleDialog={switchOffGoogleDialog}
      />
    );
  }
}
