import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

// import components
import TypographyChooseFontsSidebar from './TypographyFontsSidebar';

// import assets
import { ReactComponent as DropDownArrow } from '../assets/icons/drop-down-arrow.svg';
import { ReactComponent as DropDownArrowWhite } from '../assets/icons/drop-down-arrow-w.svg';

export default function TypographyDashboardFonts(props) {
  // console.log('rendered TypographyDashboardFonts Component');

  // general animation
  const uiFadeOut = useSpring({
    opacity: props.showChooseFontsMenu ? 1 : 0.5,
  });

  const chooseFontsMenuAppear = useSpring({
    opacity: props.showChooseFontsMenu ? 1 : 0,
    height: props.showChooseFontsMenu ? 130 : 0,
    marginTop: props.showChooseFontsMenu ? '0.8rem' : '0',
  });

  const { y } = useSpring({
    y: props.showChooseFontsMenu ? 180 : 0,
  });

  // choose fonts sidebar
  const [showChooseFontSidebar, setShowChooseFontSidebar] = useState(false);

  const chooseFontsSidebarSlide = useSpring({
    transform: showChooseFontSidebar
      ? 'translateX(-4rem)'
      : 'translateX(-24rem)',
  });

  function toggleChooseFontSidebar() {
    setShowChooseFontSidebar(!showChooseFontSidebar);
  }

  // setup choose headings or body mode
  const [chooseFontsMode, setChooseFontsMode] = useState('headingsFont');
  function setChooseontsMode(fontsMode) {
    setChooseFontsMode(fontsMode);
    // console.log('chooseFontsMode: ', chooseFontsMode);
  }

  return (
    <div className="typographyDashboardCont">
      <animated.button
        onClick={() => {
          props.toggleChooseFontsMenu();
        }}
        style={uiFadeOut}
        className={props.darkmode ? 'dropDownButton' : 'dropDownButtonDM'}
      >
        Choose Fonts
        <animated.div
          style={{
            transform: y.interpolate((y) => `rotateX(${y}deg)`),
            height: '1rem',
            position: 'relative',
            top: ' 1px',
          }}
        >
          {props.darkmode ? (
            <DropDownArrow className={'dropDownArrow'} />
          ) : (
            <DropDownArrowWhite className={'dropDownArrow'} />
          )}
        </animated.div>
      </animated.button>

      <animated.div style={chooseFontsMenuAppear}>
        <h4 className={'chooseFontHeading'}>Headings Font</h4>
        <div
          id={'headingsFontPickerDisplay'}
          className={props.darkmode ? 'chooseFontPicker' : 'chooseFontPickerDM'}
          onClick={() => {
            toggleChooseFontSidebar();
            setChooseontsMode('headingsFont');
          }}
        >
          <span style={{ fontWeight: props.headingsFontWeight }}>
            {props.headingsFontFamily + ' ' + props.headingsFontWeight}
          </span>
        </div>

        <h4 className={'chooseFontHeading'}>Body Font</h4>
        <div
          className={props.darkmode ? 'chooseFontPicker' : 'chooseFontPickerDM'}
          id={'bodyFontPickerDisplay'}
          onClick={() => {
            toggleChooseFontSidebar();
            setChooseontsMode('bodyFont');
          }}
        >
          <span style={{ fontWeight: props.bodyFontWeight }}>
            {props.bodyFontFamily + ' ' + props.bodyFontWeight}
          </span>
        </div>
      </animated.div>

      <animated.div style={chooseFontsSidebarSlide} className="navContainer">
        <TypographyChooseFontsSidebar
          toggleChooseFontSidebar={toggleChooseFontSidebar}
          darkmode={props.darkmode}
          googleFontsData={props.googleFontsData}
          chooseFontsMode={chooseFontsMode}
          setHeadingsFontCallback={props.setHeadingsFontCallback}
          setBodyFontCallback={props.setBodyFontCallback}
          changeHeadingsFontOnHover={props.changeHeadingsFontOnHover}
          changeHeadingsFontOnMouseLeave={props.changeHeadingsFontOnMouseLeave}
          changeBodyFontOnHover={props.changeBodyFontOnHover}
          changeBodyFontOnMouseLeave={props.changeBodyFontOnMouseLeave}
        />
      </animated.div>
    </div>
  );
}
