import React from 'react';
import { useSpring, animated } from 'react-spring';

import InputRange from './InputRange';

// import assets
import { ReactComponent as DropDownArrow } from '../assets/icons/drop-down-arrow.svg';
import { ReactComponent as DropDownArrowWhite } from '../assets/icons/drop-down-arrow-w.svg';

export default function TypographyDashboardSizes(props) {
  // console.log('rendered TypographyDashboardSizes Component');

  // general animation
  const uiFadeOut = useSpring({
    opacity: props.showSizesMenu ? 1 : 0.5,
  });

  // change size for complete layout
  let dashboardHeight =
    props.typographyLayoutSelection === 'Complete' ? 365 : 110;

  const SizesMenuAppear = useSpring({
    opacity: props.showSizesMenu ? 1 : 0,
    height: props.showSizesMenu ? dashboardHeight : 0,
    marginTop: props.showSizesMenu ? '0.7rem' : '0.2rem',
  });

  const { y } = useSpring({
    y: props.showSizesMenu ? 180 : 0,
  });

  return (
    <div className={'typographyDashboardCont'}>
      <animated.button
        onClick={() => {
          props.toggleSizesMenu();
        }}
        style={uiFadeOut}
        className={props.darkmode ? 'dropDownButton' : 'dropDownButtonDM'}
      >
        Adjust Sizes
        <animated.div
          style={{
            transform: y.interpolate((y) => `rotateX(${y}deg)`),
            height: '1rem',
            position: 'relative',
            top: ' 1px',
          }}
        >
          {props.darkmode ? (
            <DropDownArrow className={'dropDownArrow'} />
          ) : (
            <DropDownArrowWhite className={'dropDownArrow'} />
          )}
        </animated.div>
      </animated.button>
      <animated.div style={SizesMenuAppear}>
        <InputRange
          darkmode={props.darkmode}
          setFontValues={props.setFontSizeValues}
          rangeHeading={'H1 –'}
          rangeElementId={'rangeInputValH1'}
          rangeDisplayElementId={'h1SizeDisplay'}
          displayElementID={'headingsH1'}
        />
        {props.typographyLayoutSelection === 'Complete' ? (
          <div>
            <InputRange
              darkmode={props.darkmode}
              setFontValues={props.setFontSizeValues}
              rangeHeading={'H2 –'}
              rangeElementId={'rangeInputValH2'}
              rangeDisplayElementId={'h2SizeDisplay'}
              displayElementID={'headingsH2'}
            />
            <InputRange
              darkmode={props.darkmode}
              setFontValues={props.setFontSizeValues}
              rangeHeading={'H3 –'}
              rangeElementId={'rangeInputValH3'}
              rangeDisplayElementId={'h3SizeDisplay'}
              displayElementID={'headingsH3'}
            />
            <InputRange
              darkmode={props.darkmode}
              setFontValues={props.setFontSizeValues}
              rangeHeading={'H4 –'}
              rangeElementId={'rangeInputValH4'}
              rangeDisplayElementId={'h4SizeDisplay'}
              displayElementID={'headingsH4'}
            />
            <InputRange
              darkmode={props.darkmode}
              setFontValues={props.setFontSizeValues}
              rangeHeading={'H5 –'}
              rangeElementId={'rangeInputValH5'}
              rangeDisplayElementId={'h5SizeDisplay'}
              displayElementID={'headingsH5'}
            />
            <InputRange
              darkmode={props.darkmode}
              setFontValues={props.setFontSizeValues}
              rangeHeading={'H6 –'}
              rangeElementId={'rangeInputValH6'}
              rangeDisplayElementId={'h6SizeDisplay'}
              displayElementID={'headingsH6'}
            />
          </div>
        ) : null}
        <InputRange
          darkmode={props.darkmode}
          setFontValues={props.setFontSizeValues}
          rangeHeading={'P –'}
          rangeElementId={'rangeInputValP'}
          rangeDisplayElementId={'pSizeDisplay'}
          displayElementID={'bodyP'}
        />
      </animated.div>
    </div>
  );
}
