import React from 'react';

export default function GoogleFontsDialog(props) {
  return (
    <div className="lSPageCont">
      <div className="lSPageContent">
        <h2>Google Fonts Privacy</h2>
        <p>
          This app uses the Google Fonts API to display external fonts. This is
          a service provided by Google Inc., Gordon House, Barrow Street, Dublin
          4, Irland (hereinafter: Google). <br /> Through certification
          according to the{' '}
          <a
            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
            target="_blank"
            rel="noreferrer"
          >
            EU-US Privacy Shield
          </a>
          &nbsp;Google guarantees that it will follow the EU's data protection
          regulations when processing data in the United States. <br />
          <br />
          To enable the display of the fonts in this app, several connections to
          the Google server in the USA are established whenever the app is
          accessed. The legal basis is Art. 6 Para. 1 lit. f) GDPR. The
          legitimate interest lies in the optimization and economic operation of
          this app. When you access this app, a connection to Google is
          established from which Google can identify the site from which your
          request has been sent and to which IP address the fonts are being
          transmitted for display. <br />
          <br />
          Google offers detailed information at: <br />
          <a
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noreferrer"
          >
            https://adssettings.google.com/authenticated
          </a>{' '}
          &{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/privacy
          </a>{' '}
          <br />
          in particular on options for preventing the use of data.
        </p>
        <p className="mobileDataWarning">
          WARNING - This App can download up to 80 mb of font data. So if you
          are on a mobile device please make sure you are connected via WiFi.
        </p>
        <div className="flexRow">
          <a href="https://google.com">
            <button className={'fontCategoryButton'}>Leave</button>
          </a>
          <button
            className="fontCategoryButton enterButton"
            onClick={() => props.switchOffGoogleDialog()}
          >
            Enter
          </button>
        </div>
      </div>
    </div>
  );
}
