import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

// import assets
import { ReactComponent as DropDownArrow } from '../assets/icons/drop-down-arrow.svg';
import { ReactComponent as DropDownArrowWhite } from '../assets/icons/drop-down-arrow-w.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/download-icon-b.svg';
import { ReactComponent as DownloadIconWhite } from '../assets/icons/download-icon-w.svg';

export default function TypographyDashboardCSS(props) {
  // console.log('rendered TypographyDashboardCSS Component');

  // general animation
  const uiFadeOut = useSpring({
    opacity: props.showCSSMenu ? 1 : 0.5,
  });

  const cssMenuAppear = useSpring({
    opacity: props.showCSSMenu ? 1 : 0,
    height: props.showCSSMenu ? 130 : 0,
    marginTop: props.showCSSMenu ? '0.8rem' : '0',
  });

  const { y } = useSpring({
    y: props.showCSSMenu ? 180 : 0,
  });

  // 77 CSS filename
  const [fileName, setFileName] = useState('Style');

  // filename input resize
  function resizeInput() {
    this.style.width = this.value.length + 'ch';
  }

  useEffect(() => {
    // filename input resize
    let cssFileNameInput = document.getElementById('cssFileNameInput');
    cssFileNameInput.addEventListener('input', resizeInput);
    resizeInput.call(cssFileNameInput);
  });

  return (
    <div className="typographyDashboardCont">
      <animated.button
        onClick={() => {
          props.toggleCSSMenu();
        }}
        style={uiFadeOut}
        className={props.darkmode ? 'dropDownButton' : 'dropDownButtonDM'}
      >
        Export CSS
        <animated.div
          style={{
            transform: y.interpolate((y) => `rotateX(${y}deg)`),
            height: '1rem',
            position: 'relative',
            top: ' 1px',
          }}
        >
          {props.darkmode ? (
            <DropDownArrow className={'dropDownArrow'} />
          ) : (
            <DropDownArrowWhite className={'dropDownArrow'} />
          )}
        </animated.div>
      </animated.button>

      <animated.div style={cssMenuAppear}>
        <h4 className={'chooseFontHeading'}>Set Filename</h4>
        <div
          className={
            props.darkmode ? 'cssFilenameInputCont' : 'fontDownloadDisplayDM'
          }
        >
          <input
            id="cssFileNameInput"
            className={props.darkmode ? 'cssFileNameExt' : 'cssFileNameExtDM'}
            type="text"
            defaultValue={fileName}
            onInput={(e) => {
              if (e.target.value !== '') {
                setFileName(e.target.value);
              } else if (e.target.value === '') {
                setFileName('Style');
              }
            }}
          />
          <input
            className={props.darkmode ? 'cssFileNameExt' : 'cssFileNameExtDM'}
            type="text"
            value=".css"
            disabled
          />
        </div>

        <h4 className="chooseFontHeading">Download .css</h4>
        <div
          className={
            props.darkmode ? 'fontDownloadDisplay' : 'fontDownloadDisplayDM'
          }
          onClick={() => props.cssDownloadTrigger(fileName)}
        >
          <span>Download</span>
          {props.darkmode ? (
            <DownloadIcon className={'downloadIcon'} />
          ) : (
            <DownloadIconWhite className={'downloadIcon'} />
          )}
        </div>
      </animated.div>
    </div>
  );
}
