import React from 'react';

export default function TypographyAlignButton(props) {
  function onClickFunctions() {
    props.setTypographyAlignmentCallback(props.name);
    props.setActiveButtonFunction(props.name);
  }

  return (
    <div className={'alignmentButtonCont'}>
      <button
        className={
          props.darkmode
            ? props.activeButtonState === props.name
              ? 'fontCategoryButtonActive'
              : 'fontCategoryButton'
            : props.activeButtonState === props.name
            ? 'fontCategoryButtonActiveDM'
            : 'fontCategoryButtonDM'
        }
        onClick={() => onClickFunctions()}
      >
        {props.icon}
      </button>
    </div>
  );
}
