import React from 'react';
import { motion } from 'framer-motion';

// import components
import NavSidebarItem from './NavSidebarItem';

// import assets
import { ReactComponent as Logo } from '../assets/icons/logo-w.svg';
import { ReactComponent as TypographyMenu } from '../assets/icons/typography-menu.svg';
import { ReactComponent as SupportMenu } from '../assets/icons/support-menu.svg';
import { ReactComponent as AboutMenu } from '../assets/icons/about-menu.svg';
import { ReactComponent as ContactMenu } from '../assets/icons/contact-menu.svg';
import { ReactComponent as Copywright } from '../assets/icons/copywright.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close-icon.svg';
import { ReactComponent as CloseIconWhite } from '../assets/icons/close-icon-w.svg';

export default function NavSidebar(props) {
  const SidebarItems = [
    {
      name: 'Typography',
      to: '/',
      icon: <TypographyMenu className={'navSidebarItemIcon'} />,
    },
    {
      name: 'Support',
      to: '/support',
      icon: <SupportMenu className={'navSidebarItemIcon'} />,
    },
    {
      name: 'About',
      to: '/about',
      icon: <AboutMenu className={'navSidebarItemIcon'} />,
    },
    {
      name: 'Contact',
      to: '/contact',
      icon: <ContactMenu className={'navSidebarItemIcon'} />,
    },
  ];

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 1, x: '-100%' },
  };

  return (
    <motion.div
      className="nav"
      initial={false}
      animate={props.showNavSidebar ? 'open' : 'closed'}
      variants={variants}
      transition={{ ease: 'easeInOut' }}
    >
      <div className="navSidebarItemContainer">
        <div
          onClick={() => {
            props.toggleNavSidebar();
          }}
          className={
            props.darkmode ? 'sidebarBackButton' : 'sidebarBackButtonDM'
          }
        >
          {props.darkmode ? (
            <CloseIcon className="sidebarBackButtonIcon" />
          ) : (
            <CloseIconWhite className="sidebarBackButtonIcon" />
          )}
        </div>
        {SidebarItems.map((item) => {
          return (
            <NavSidebarItem
              key={item.name}
              name={item.name}
              to={item.to}
              icon={item.icon}
              toggleNavSidebar={props.toggleNavSidebar}
            />
          );
        })}
      </div>
      <div>
        <Logo className="logoWhite" />
        <div className="navBarCredits">
          <Copywright className="copywrightIcon" />
          {new Date().getFullYear()}, All rights reserved.
        </div>
        <span className="navBarCredits">
          Created by{' '}
          <a
            className="navBarCreditsLink"
            href="https://design-berlin.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            Manuel Schulz.
          </a>
        </span>
        <br />
        <span className="navBarCredits">Version 1.0.0</span>
      </div>
    </motion.div>
  );
}
