import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import TypographyDashboardRadio from './RadioButton';

// import assets
import { ReactComponent as DropDownArrow } from '../assets/icons/drop-down-arrow.svg';
import { ReactComponent as DropDownArrowWhite } from '../assets/icons/drop-down-arrow-w.svg';

export default function TypographyDashboardLayouts(props) {
  // console.log('rendered TypographyDashboardLayouts Component');

  // general animation
  const uiFadeOut = useSpring({
    opacity: props.showLayoutsMenu ? 1 : 0.5,
  });

  const LayoutMenuAppear = useSpring({
    opacity: props.showLayoutsMenu ? 1 : 0,
    height: props.showLayoutsMenu ? 110 : 0,
    marginTop: props.showLayoutsMenu ? '0.7rem' : '0',
  });

  const { y } = useSpring({
    y: props.showLayoutsMenu ? 180 : 0,
  });

  // handle radio button state
  const [radioButtonValue, setRadioButtonValue] = useState('Basic');

  return (
    <div className={'typographyDashboardCont'}>
      <animated.button
        onClick={() => {
          props.toggleLayoutsMenu();
        }}
        style={uiFadeOut}
        className={props.darkmode ? 'dropDownButton' : 'dropDownButtonDM'}
      >
        Choose Layout
        <animated.div
          style={{
            transform: y.interpolate((y) => `rotateX(${y}deg)`),
            height: '1rem',
            position: 'relative',
            top: ' 1px',
          }}
        >
          {props.darkmode ? (
            <DropDownArrow className={'dropDownArrow'} />
          ) : (
            <DropDownArrowWhite className={'dropDownArrow'} />
          )}
        </animated.div>
      </animated.button>
      <animated.div style={LayoutMenuAppear}>
        <div className="flexColumn">
          <TypographyDashboardRadio
            darkmode={props.darkmode}
            name="fontLayouts"
            id="basic"
            value="Basic"
            htmlFor="basic"
            radioText="Basic"
            onClick={() => {
              props.changeFontLayoutBasic();
              setRadioButtonValue('Basic');
            }}
            radioButtonValue={radioButtonValue}
          />
          <TypographyDashboardRadio
            darkmode={props.darkmode}
            name="fontLayouts"
            id="block"
            value="Block"
            htmlFor="block"
            radioText="Block"
            onClick={() => {
              props.changeFontLayoutBlock();
              setRadioButtonValue('Block');
            }}
            radioButtonValue={radioButtonValue}
          />
          <TypographyDashboardRadio
            darkmode={props.darkmode}
            name="fontLayouts"
            id="complete"
            value="Complete"
            htmlFor="complete"
            radioText="Complete"
            onClick={() => {
              props.changeFontLayoutComplete();
              setRadioButtonValue('Complete');
            }}
            radioButtonValue={radioButtonValue}
          />
        </div>
      </animated.div>
    </div>
  );
}
