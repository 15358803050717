import React from 'react';

export default function TypographyDashboardRadio(props) {
  return (
    <div className="radioWrapper">
      <input
        className="radioState"
        type="radio"
        name={props.name}
        id={props.id}
        value={props.value}
        onClick={props.onClick}
        checked={props.radioButtonValue === props.value}
        onChange={() => null}
      />
      <label className="radioLabel" htmlFor={props.htmlFor}>
        <div
          className={props.darkmode ? 'radioIndicator' : 'radioIndicatorDM'}
        ></div>
        <span className={props.darkmode ? 'radioText' : 'radioTextDM'}>
          {props.radioText}
        </span>
      </label>
    </div>
  );
}
