const typographyDisplayDataComplete = [
  {
    id: 'item-1',
    htmlTag: 'h1',
    content: 'Lorem Ipsum Dolor Sit Amet Consectetuer',
  },
  {
    id: 'item-2',
    htmlTag: 'p',
    content:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
  },
  {
    id: 'item-3',
    htmlTag: 'h2',
    content: 'Lorem Ipsum Dolor Sit Amet Consectetuer',
  },
  {
    id: 'item-4',
    htmlTag: 'p',
    content:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
  },
  {
    id: 'item-5',
    htmlTag: 'h3',
    content: 'Lorem Ipsum Dolor Sit Amet Consectetuer',
  },
  {
    id: 'item-6',
    htmlTag: 'p',
    content:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
  },
  {
    id: 'item-7',
    htmlTag: 'h4',
    content: 'Lorem Ipsum Dolor Sit Amet Consectetuer',
  },
  {
    id: 'item-8',
    htmlTag: 'p',
    content:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
  },
  {
    id: 'item-9',
    htmlTag: 'h5',
    content: 'Lorem Ipsum Dolor Sit Amet Consectetuer',
  },
  {
    id: 'item-10',
    htmlTag: 'p',
    content:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
  },
  {
    id: 'item-11',
    htmlTag: 'h6',
    content: 'Lorem Ipsum Dolor Sit Amet Consectetuer',
  },
  {
    id: 'item-12',
    htmlTag: 'p',
    content:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.',
  },
];

export default typographyDisplayDataComplete;
