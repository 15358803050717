import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavSidebarItem(props) {
  return (
    <NavLink
      exact={true}
      to={props.to}
      className={'navSidebarItem'}
      onClick={props.toggleNavSidebar}
    >
      {props.icon}
      <span>{props.name}</span>
    </NavLink>
  );
}
