export const cssVarsAndProps = [
  {
    variable: 'FontFamily',
    property: 'font-family',
  },
  {
    variable: 'FontWeight',
    property: 'font-weight',
  },
  {
    variable: 'FontSize',
    property: 'font-size',
  },
  {
    variable: 'TextAlign',
    property: 'text-align',
  },
  {
    variable: 'LineHeight',
    property: 'line-height',
  },
  {
    variable: 'MarginBottom',
    property: 'margin-bottom',
  },
];

const CSSDataBasic = {
  // comment: '/* montserrat-regular - latin */',
  comment: '/* Your Styles */',
  selectorH1: 'h1 ',
  h1: {
    'font-family': '',
    'font-weight': '',
    'font-size': '',
    'text-align': '',
    'line-height': '',
    'margin-bottom': '',
  },
  selectorP: 'p ',
  p: {
    'font-family': '',
    'font-weight': '',
    'font-size': '',
    'text-align': '',
    'line-height': '',
    'margin-bottom': '',
  },
};
export default CSSDataBasic;

// css data computing object
export const cssSelectorsBasic = [
  {
    selector: 'h1',
    id: 'headingsH1',
  },
  {
    selector: 'p',
    id: 'bodyP',
  },
];
